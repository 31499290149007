@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'nhg';
  font-weight: 400;
  font-style: normal;
  src: url('../public/fonts/Neue-Haas-Grotesk-Regular.eot');
  src: url('../public/fonts/Neue-Haas-Grotesk-Regular.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Neue-Haas-Grotesk-Regular.woff2') format('woff2'),
    url('../public/fonts/Neue-Haas-Grotesk-Regular.woff') format('woff');
}
@font-face {
  font-family: 'nhg';
  font-weight: 400;
  font-style: italic;
  src: url('../public/fonts/Neue-Haas-Grotesk-Italic.eot');
  src: url('../public/fonts/Neue-Haas-Grotesk-Italic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Neue-Haas-Grotesk-Italic.woff2') format('woff2'),
    url('../public/fonts/Neue-Haas-Grotesk-Italic.woff') format('woff');
}
@font-face {
  font-family: 'nhg';
  font-weight: 500;
  font-style: normal;
  src: url('../public/fonts/Neue-Haas-Grotesk-Medium.eot');
  src: url('../public/fonts/Neue-Haas-Grotesk-Medium.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Neue-Haas-Grotesk-Medium.woff2') format('woff2'),
    url('../public/fonts/Neue-Haas-Grotesk-Medium.woff') format('woff');
}
@font-face {
  font-family: 'nhg';
  font-weight: 500;
  font-style: italic;
  src: url('../public/fonts/Neue-Haas-Grotesk-Medium-Italic.eot');
  src: url('../public/fonts/Neue-Haas-Grotesk-Medium-Italic.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Neue-Haas-Grotesk-Medium-Italic.woff2') format('woff2'),
    url('../public/fonts/Neue-Haas-Grotesk-Medium-Italic.woff') format('woff');
}
@font-face {
  font-family: 'adieu';
  /* font-weight: 400; */
  /* font-style: normal; */
  src: url('../public/fonts/Adieu-Light.eot');
  src: url('../public/fonts/Adieu-Light.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Adieu-Light.woff2') format('woff2'),
    url('../public/fonts/Adieu-Light.woff') format('woff');
}

@font-face {
  font-family: 'adieu-bold';
  /* font-weight: 800; */
  /* font-style: normal; */
  src: url('../public/fonts/Adieu-Black.eot');
  src: url('../public/fonts/Adieu-Black.eot?#iefix') format('embedded-opentype'),
    url('../public/fonts/Adieu-Black.woff2') format('woff2'),
    url('../public/fonts/Adieu-Black.woff') format('woff');
}

@font-face {
  font-family: 'Ace Lift';
  src: url('../public/fonts/Ace-Regular.eot');
  src: url('../public/fonts/Ace-Regular.otf') format('otf'),
    url('../public/fonts/Ace-Regular.woff2') format('woff2'),
    url('../public/fonts/Ace-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Ace Lift Bold';
  src: url('../public/fonts/Ace-Bold.eot');
  src: url('../public/fonts/Ace-Bold.otf') format('otf'),
    url('../public/fonts/Ace-Bold.woff2') format('woff2'),
    url('../public/fonts/Ace-Bold.woff') format('woff');
}

@font-face {
  font-family: 'cardinalfruit-regular';
  src: url('../public/fonts/cardinalfruitweb-regular.woff2') format('woff2'),
       url('../public/fonts/cardinalfruitweb-regular.woff') format('woff');
  }
@font-face {
  font-family: 'cardinalfruit-italic';
  src: url('../public/fonts/cardinalfruitweb-italic.woff2') format('woff2'),
       url('../public/fonts/cardinalfruitweb-italic.woff') format('woff');
  }

* {
  font-family: nhg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  position: relative;
  background-color: #000000;
}
img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Lazyload images | npm package: https://www.npmjs.com/package/lazysizes */
.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 300ms;
}

/* React Date Picker */
.react-datepicker {
  @apply !bg-black !rounded-none !font-adieu;
}

.react-datepicker__header {
  @apply !bg-black;
}

.react-datepicker-wrapper {
  @apply w-full;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
  @apply !text-white;
}

.react-datepicker__input-container input {
  @apply w-full border px-[18px] h-[44px] flex items-center font-normal font-adieu leading-none tracking-[0.15em] uppercase text-sm 3xl:text-sm 4xl:text-[15px] text-white placeholder:text-white/40 focus:border-white/40 focus:outline-none transition bg-black;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  @apply !bg-orange-400 !text-white;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  @apply !text-orange-400 !bg-black;
}

:root{
  --default-scale: 1.4;
  --pinterest-landscape: 1.4;
  --pinterest-portrait: 1.47;
}

/* For embed social links */
.iframely-responsive > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1 solid #ABABAB;
  padding: 15px;
}

.iframely-responsive {
  padding: 0 !important;
}

/* Moodboard page grid item with fixed 400 height and Minmax(256px - 1fr) width, landscape opcupy two columns, fixed 48px gap */
.iframely-instagram > .iframely-responsive > iframe {
  width: calc(100% * var(--default-scale));
  height: calc(100% * var(--default-scale));
  transform: scale(calc(1/var(--default-scale)));
  top: calc((100% - 100% * var(--default-scale))/2);
  left: calc((100% - 100% * var(--default-scale))/2);
}

.iframely-tiktok > .iframely-responsive > iframe {
  width: calc(100% * var(--default-scale));
  height: calc(100% * var(--default-scale));
  transform: scale(calc(1/var(--default-scale)));
  top: calc((100% - 100% * var(--default-scale))/2);
  left: calc((100% - 100% * var(--default-scale))/2);
}

.social-link-pinterest-landscape > div > .iframely-responsive > iframe {
  width: calc(100% * var(--pinterest-landscape));
  height: calc(100% * var(--pinterest-landscape));
  transform: scale(calc(1/var(--pinterest-landscape)));
  top: calc((100% - 100% * var(--pinterest-landscape))/2);
  left: calc((100% - 100% * var(--pinterest-landscape))/2);
}

.social-link-pinterest-portrait > div > .iframely-responsive > iframe {
  width: calc(100% * var(--pinterest-portrait));
  height: calc(100% * var(--pinterest-portrait));
  transform: scale(calc(1/var(--pinterest-portrait)));
  top: calc((100% - 100% * var(--pinterest-portrait))/2);
  left: calc((100% - 100% * var(--pinterest-portrait))/2);
}

/* Billboard carousel style */
.swiper {
  height: 100%;
  margin-top: -112px;
  margin-left: -64px !important;
  margin-right: -64px !important;
}

.swiper > .swiper-pagination {
  bottom: 45px !important;
  width: 50% !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.swiper > .swiper-pagination > .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
}

.swiper > .swiper-pagination > .swiper-pagination-bullet {
  opacity: 1;
  border: white solid 1px;
  background-color: transparent;
}

.swiper > .swiper-pagination > .swiper-pagination-bullet-active {
  background-color: white;
}

.half-oval-clip-left {
  clip-path: ellipse(100% 50% at 0% 50%);
}

.half-oval-clip-right {
  clip-path: ellipse(100% 50% at 100% 50%);
}


@layer utilities {
  .underline-animation {
    @apply relative inline-block cursor-pointer;
  }
  .underline-animation::after {
    content: '';
    @apply absolute bottom-[-2px] left-1/2 w-0 h-[1px] bg-current transition-all duration-300 ease-in-out;
    transform: translateX(-50%);
  }
  .underline-animation:hover::after {
    @apply left-0 w-full;
    transform: translateX(0);
  }
}
